//***** NEW COLORS ******//
$hd-purple-1: #5d0b71;
$hd-purple-2: #89178f;
$hd-purple-3: #b861d8;
$hd-purple-4: #efcaff;
$hd-purple-5: #fcf4ff;

$hd-purple-6: #f3eef9;

$hd-purple-1-pale: rgba($hd-purple-1, 0.1);

$hd-green-1: #00f0af;
$hd-green-2: #5affcc;
$hd-green-3: #a8ffde;
$hd-green-4: #e6fff7;
$hd-green-5: #d4fff1;

$hd-green-1-pale: rgba($hd-green-1, 0.1);

$hd-yellow-1: #ced000;
$hd-yellow-2: #f0e74f;
$hd-yellow-3: #f9f299;
$hd-yellow-4: #fbfadf;
$hd-yellow-star: #e9b604;

$hd-yellow-1-pale: rgba($hd-yellow-1, 0.1);

$hd-beige-1: #f2e8dd;
$hd-beige-2: #f7f1eb;
$hd-beige-3: #fcfaf8;
$hd-beige-4: #fdfcfa;

$hd-grey-1: #2b2b2b;
$hd-grey-2: #5c5c5c;
$hd-grey-3: #858585;
$hd-grey-4: #bbbbbb;
$hd-grey-5: #eeeeee;

$hd-grey-7: #f5f5f5;

$hd-grey-1-pale: rgba($hd-grey-1, 0.1);

$hd-white: #ffffff;
$hd-white-2: #fcfbff;

$hd-red-3: #ff4e4e;
$hd-red-1: #ff5e5e;
$hd-red-2: #ff7070;

$hd-red-1-pale: rgba($hd-red-1, 0.1);

$hd-dark-gradient: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));

$background-color: $hd-white;
$main-color: $hd-white;
$primary-color: $hd-grey-1;
$secondary-color: #6c6c6c;
$title-color: $hd-grey-1;

$hd-error-color: #f82e47;

$page-max-width: 1000px;
$hd-client-max-width: 1176px;
$hd-form-max-width: 704px;

/*design*/
$border-radius: 24px;
$border-radius-button: 30px;
$box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);

/*animation*/
$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

/*Desktop text*/
$hd-h1-font-size: 64px;
$hd-h1-font-weight: 700;

$hd-h2-font-size: 48px;
$hd-h2-font-weight: 600;

$hd-h3-font-size: 28px;
$hd-h3-font-weight: 700;

$hd-h4-font-size: 24px;
$hd-h4-font-weight: 700;

$hd-h5-font-size: 20px;
$hd-h5-font-weight: 700;

$hd-font-weight: 400;
$hd-font-weight-bold: 700;

$hd-font-size: 18px;
$hd-font-size-xxx-large: 56px;
$hd-font-size-extra-extra-large: 42px;
$hd-font-size-extra-large: 24px;
$hd-font-size-title: 32px;
$hd-font-size-large: 18px;
$hd-font-size-medium: 16px;
$hd-font-size-small: 14px;
$hd-font-size-extra-small: 12px;

$hd-font-size-extra-small-button: 14px;
$hd-font-size-small-button: 16px;
$hd-font-size-medium-button: 18px;
$hd-font-size-large-button: 22px;
$hd-font-size-extra-large-button: 24px;
$hd-font-size-link-button: 15px;

/*Mobile text*/
$hd-mobile-h1-font-size: 36px;
$hd-mobile-h2-font-size: 24px;
$hd-mobile-h3-font-size: 22px;
$hd-mobile-client-h4-font-size: 24px;
$hd-mobile-h4-font-size: 16px;
$hd-mobile-h5-font-size: 20px;
$hd-mobile-font-size: 16px;
$hd-mobile-font-size-small-button: 16px;
