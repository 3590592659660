@use '@hellodarwin/core/scss/_variables' as variables;

/*Blacklist form CSS*/
.blacklist-form-list {
  .blacklist-form-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;

    &:hover {
      background-color: rgba(variables.$secondary-color, 0.1);
    }

    a {
      margin: 0;
      color: variables.$secondary-color;

      &:hover {
        color: variables.$primary-color;
      }
    }

    .blacklist-form-list-delete {
      cursor: pointer;
      margin: 0;

      &:hover {
        color: variables.$primary-color;
      }
    }
  }
}
