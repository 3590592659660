@use '@hellodarwin/core/scss/_variables' as variables;

/*Subscription preview card CSS*/
.subscription-preview {
  padding-top: 1rem;
  margin: 0 0 1rem 0;
  background-color: variables.$main-color;
  border-radius: 10px;

  .subscription-preview-item:last-of-type {
    margin-bottom: 0;
  }
}
