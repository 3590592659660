@use './variables' as variables;

.ant-collapse-expand-icon {
  align-self: center;
}

.ant-avatar-square {
  border-radius: 12px;
}

.ant-notification {
  z-index: 3000;
}

.ant-checkbox-inner {
  border-color: variables.$hd-purple-1;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-picker-dropdown {
  z-index: 2500;
}

.ant-select-dropdown,
.ant-select-dropdown-placement-bottomLeft {
  z-index: 9999 !important;
  line-height: 1.6;
}

.ant-image-preview-mask,
.ant-image-preview-wrap {
  z-index: 4000;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.ant-layout {
  .ant-layout-sider {
    padding: 0;
  }
}
.ant-menu-submenu-popup {
  z-index: 1150;
  .ant-menu-vertical.ant-menu-sub {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 24px;
    background-color: variables.$hd-purple-2;
    border: 1px solid variables.$hd-purple-1;
    box-shadow: 0px 2px 6px rgba(25, 0, 51, 0.06);
    border-radius: 4px;
    overflow: hidden;
    .ant-menu-item {
      color: variables.$hd-purple-4;
      margin: 0;
      height: fit-content;
      border-radius: 0;
      align-items: flex-start !important;
      .ant-menu-title-content,
      .ant-menu-submenu-title {
        margin-inline-start: 0 !important;
      }
      &:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover {
        color: variables.$hd-white;
      }
      &.ant-menu-item-selected {
        color: variables.$hd-green-1;
      }
    }

    &::before,
    &::after {
      display: none;
    }
  }
}

.ant-steps {
  .ant-steps-item-icon {
    .ant-steps-icon {
      top: -1.5px;
    }
  }
}

.ant-list-item {
  .ant-list-item-meta {
    .ant-list-item-meta-content {
      width: fit-content !important;
    }
  }
}
