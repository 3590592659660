@use '@hellodarwin/core/scss/_variables' as variables;

.quick-action {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  background-color: variables.$main-color;
  border-radius: 5px;
  padding: 0.5rem;
  box-shadow: variables.$box-shadow;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &:hover {
    color: variables.$primary-color;
    transform: translateY(-1px);
  }

  span {
    font-size: 18px;
  }

  &.active {
    color: variables.$primary-color;
    transform: translateY(-1px);
  }
}
