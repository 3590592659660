.auth0-container {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  align-items: center;
}

.auth0-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.auth0-id-container {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  align-items: center;
}
