/*Action bar CSS*/
.action-bar {
  transform-origin: 0;
  z-index: 997;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;

  .action-bar-title {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.5rem;

    .action-bar-quick-action {
      display: flex;
      gap: 0.5rem;
    }
  }
}
