@use '@hellodarwin/core/scss/_variables' as variables;
@use '@hellodarwin/core/scss/antd-common' as *;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap');
@import url('https://use.typekit.net/lht6zme.css');

/*Global custom CSS*/
body {
  height: 100vh;
  font-family: 'Inter', sans-serif;
  margin: 0;
  * {
    box-sizing: border-box;
  }
}

.quill {
  border-radius: variables.$border-radius;
  height: inherit;
  display: flex;
  flex-direction: column;
  border-radius: variables.$border-radius;
  overflow: hidden;
  background-color: variables.$hd-white;
  transition: variables.$transition;
  .ql-toolbar {
    padding: 0.5rem 10px;
    background-color: variables.$hd-purple-5;
    border: none;
    border-bottom: 1px solid variables.$hd-purple-5;
  }
  .ql-container {
    padding: 12px 18px;
    border: none;
    height: 100% !important;
    border-radius: 0;
    border-bottom-left-radius: variables.$border-radius;
    border-bottom-right-radius: variables.$border-radius;
    border: 1px solid variables.$hd-purple-5;

    .ql-editor {
      padding: 0;
      min-height: 200px;
    }
    &:hover {
      border: 1px solid variables.$hd-purple-4;
    }
  }

  .ql-toolbar.ql-snow {
    font-family: Inter, Helvetica, Arial, sans-serif;
    border: none !important;
  }
}
