.manifest-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .manifest-action-container {
    display: flex;
    gap: 1rem;
  }

  .manifest-journey-controls-container {
    display: flex;
    gap: 0.5rem;
  }

  .manifest {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .manifest-card-title {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem 0;
    }

    .manifest-table {
      td {
        text-align: center;
        vertical-align: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .parent-rows {
        background-color: #f8f8f8;
      }

      .child-rows {
        box-sizing: border-box;
        border-radius: 50px;
      }

      .child-rows:hover {
        background-color: #f8f8f8;
      }
    }
  }
}
