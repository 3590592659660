@use '@hellodarwin/core/scss/_variables' as variables;
/*hd list item CSS*/
.hd-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1px;

  .hd-table-header {
    tr th {
      font-weight: normal;
      padding: 0 1rem;
    }
  }

  tbody {
    box-shadow: variables.$box-shadow;
    border-radius: variables.$border-radius;

    .hd-table-row:first-child {
      td:first-child {
        border-top-left-radius: variables.$border-radius;
      }

      td:last-child {
        border-top-right-radius: variables.$border-radius;
      }
    }

    .hd-table-row:last-child {
      td:first-child {
        border-bottom-left-radius: variables.$border-radius;
      }

      td:last-child {
        border-bottom-right-radius: variables.$border-radius;
      }
    }

    .hd-table-row {
      cursor: pointer;
      background-color: variables.$main-color;
      border-bottom: 1px solid rgba(variables.$secondary-color, 0.25);
      transition: variables.$transition;

      td {
        padding: 1rem 0.5rem;
        white-space: nowrap;
        transition: variables.$transition;
      }

      td:last-child {
        width: 10%;
        white-space: nowrap;
      }

      &:hover {
        transform: translateX(4px);

        td:first-child {
          box-shadow: -4px 0px 0px 0px variables.$primary-color;
        }
      }

      &.active {
        transform: translateX(4px);

        td:first-child {
          box-shadow: -4px 0px 0px 0px variables.$primary-color;
        }
      }
    }
  }
}
