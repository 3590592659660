@use '@hellodarwin/core/scss/_variables' as variables;

/*Match preview card CSS*/
.match-preview {
  background-color: variables.$main-color;
  border-radius: variables.$border-radius;
  margin-bottom: 1rem;

  .match-preview-item:first-of-type {
    justify-content: space-between;
    display: flex;
  }

  .match-preview-item {
    display: flex;
    justify-content: space-between;

    .match-preview-item-label {
      font-weight: bold;
      margin-right: 0.5rem;
    }
  }

  .match-preview-item:last-of-type {
    margin-bottom: 0;
  }

  .match-preview-menu {
    display: flex;
    justify-content: flex-end;

    .match-preview-menu-dropdown {
      padding: 0;

      svg {
        font-size: 24px;
        color: variables.$secondary-color;

        &:hover {
          color: variables.$primary-color;
        }
      }
    }
  }

  &.winner {
    border: 2px solid variables.$primary-color;
  }
}
