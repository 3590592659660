@use '@hellodarwin/core/scss/variables' as variables;

.search-results-title {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: variables.$hd-white;
  padding: 8px;
  border-bottom: 1px solid variables.$hd-grey_4;
  margin-bottom: 8px;
}

